const UPDATES = 
[
    {   
        version:'1.0', shortMsg:'Official Release',  
        new:[
            'Random Events [ 5 Events Added ]', 'Victory Screen', 'Automatic Server Restart', 
            'Updated to 1.19', 
            'New Death Messges', 'Remaining Players Shown', 'Sound FX', 'New Website', 
            'New Feast Items (Diamond, Iron, Gold)',
            'Kill Counter', 'NEW/REWORKED KITS: Blacksmith, Adventurer, Gambler, Engineer, Cookie Monster', 

        ],
        
        kitBuffs:
        [
            'Sunwalker has increased speed', 
            'Spirit flight last longer (prev -> 5 sec)', 
            'Lumberjack can now upgrade his axe', 
            'Chemist gets potions refreshed on kill', 
            'Switcher now gets a refresh when killing an enemy',
            'Berserker effects are twice as long',
            'Beastermaster wolfs you tame now have speed 2, and strength 1',
            'Thor cooldown reduced',
            'Snail effects last longer',
            'Viper effects last slightly longer & poision effect is stronger',
            'Milkman effects last longer',
            'Werewolf improved speed & resistance',
        ],

        kitChanges:
        [
            'Groundog now has cooldown instead of 3 uses', 
            'Turtle gains resistance when crouched',
        ],

        fixed:['A ton of bug fixes'],
        removed:['Removed Pop Up Text (can be toggled on)']
    }
]

export default UPDATES
