//@ts-nocheck
import UPDATES from "../data/updates"

const UpdateLog = () => 
{
    return(
    <div className="update-log">
        <div className="logs overflow-y-scroll h-screen">
            {
                UPDATES.map(item => <Log data={item}/>)
            }
        </div> 
    </div>
    )
}

const Log = (props: any) => 
{
    return(
        <div className="md:w-10/12 first:shadow-xl first:shadow-white lg:w-1/2 mx-auto border border-black p-3 m-4 sm:m-1 updatelog rounded-sm first:mb-12" >

            <h1 className="font-basic text-3xl rounded font-bold text-black underline">Update {props.data.version}</h1>
            <p className="message text-xl font-basic text-emerald-300 bg-black/50 w-fit mx-auto p-2">{props.data.shortMsg ? props.data.shortMsg : null}</p>
            <br/>

        <ul className="font-basic p-2">

                <div className="bg-black/50 rounded text-white">
                    <h1 className="text-orange-600 font-bold">NEW</h1>
                    { props.data.new.map(item => <li>+ {item}</li>) } 
                </div>

                <br/>

                <div className="bg-black/50 text-white rounded">
                    <h1 className="text-emerald-300 font-bold">KIT BUFFS</h1>
                    { props.data.kitBuffs.map(item => <li> + {item}</li>) }
                </div>

                <br/>

                <div className="bg-black/50 text-white">
                    <h1 className="text-blue-400 font-bold ">KIT CHANGES</h1>
                    { props.data.kitChanges.map(item => <li>* {item}</li>) }
                </div>

                <br/>

                <div className="bg-black/50 text-white">
                    <h1 className="text-red-500 font-bold ">REMOVED</h1>
                    { props.data.removed.map(item => <li>- {item}</li>) }
                </div>

                <br/>

                <div className="bg-black/50 text-white">
                    <h1 className="text-orange-500 font-bold">FIXED</h1>
                    { props.data.fixed.map(item => <li>{item}</li>) }
                </div>

            </ul>
        </div>
    )
}

export default UpdateLog
