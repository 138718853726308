import AboutTheGameText from "../data/aboutthegame";

const AboutGame = () => 
{
    return(
    <div className="aboutgame">
        <div className="backgroundimage-aboutthegame w-1/2 mx-auto p-2 border mt-2 text-white sm:w-screen">
            <h1 className="font-title underline text-2xl">About The Game</h1>
            <br/>
            <p className="text-xl font-basic">{AboutTheGameText}</p>
        </div>
    </div>
    )
}

export default AboutGame
