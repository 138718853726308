import React from "react"
import KITDATA from "../data/kitdata"

class Kits extends React.Component<any, any>
{

    constructor(props: any) {
        super(props)
        this.state = { kitSelected: null}
    }

    render(){
        return(
    <div className="kits">
        <div className="grid grid-rows-6 md:grid-flow-row sm:grid-flow-row map-2 grid-flow-col sm:grid-cols-1 md:grid-cols-2 sm:gap-1 font-basic m-4 sm:m-0 mx-auto ">
            { 
                KITDATA.map( item => 
                <a className=
                    "Kit border border-white border-2 rounded-lg p-2 bg-black text-white h-fit sm:h-100% sm:w-screen md:mx-auto w-3/4 hover:bg-white hover:text-black transition-all duration-100 ease-out drop-shadow select-none cursor-pointer">
                            <h1 className="text-xl sm:text-sm underline">{item.name}</h1>
                            <p className="mx-auto sm:text-sm">{item.description}</p>
                            <img src={item.image} className="h-32 w-32 mx-auto border"/>
                            <br/>

                            {
                                item.price == "free" 
                                ? <span className="bg-green-400 text-black rounded p-1">Free</span> 
                                : <span className="bg-orange-400 text-black rounded p-1">${item.price}</span> 
                            } 

                        </a>
                )
            }
        </div>
    </div>
        )
    }
}


const MoreInfoPanel = (props: any) => {
    return(
        <div className="fixed top-0 right-0 bg-gray-900 h-screen w-1/4 md:w-1/2 transition-all rounded-xl">
            <h1 className="text-3xl font-basic">{props.data}</h1>
        </div>
    )
}

export default Kits
