import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import './App.css'

// Components
import AboutGame from './components/aboutgame'
import AboutUs from './components/aboutus'
import Kits from './components/kits'
import Socials from './components/socials';
import UpdateLog from './components/updatelog';

function App() {
  return (
    <BrowserRouter>
        <div className="App overflow-y-scroll h-screen">
                  <div className='backgroundimage'/>
                  <div className='bg-black h-screen w-screen absolute z-[-2]'/>
                  <h1 className="text-3xl lg:text-4xl sm:text-2xl font-title text-black border py-3 bg-[url('./images/cobblestone_texture.png')] uppercase select-none shadow-xl">Kit PvP Official</h1>
                  <NavBar/>
                  <div className='content'>
                    <Routes>
                        <Route path="/aboutgame" element={<AboutGame/>}/> 
                        <Route path="/kits" element={<Kits/>}/> 
                        <Route path="/socials" element={<Socials/>}/> 
                        <Route path="/updatelog" element={<UpdateLog/>}/> 
                    </Routes>
                  </div>
        </div>
    </BrowserRouter>
 );
}

const NavBar = () => {
    return(
        <div className='w-screen flex font-basic text-xl text-white select-none mx-auto'>
            <Link to="/kits" className='mcbutton flex-1'>Kits</Link>
            <Link to="/aboutgame" className='mcbutton flex-1'>About The Game</Link>
            <Link to="/updatelog" className='mcbutton flex-1'>Update Log</Link>
            <Link to="/socials" className='mcbutton flex-1'>Socials</Link>
        </div>
    )
}

export default App;
