import AdventurerImg from "./IMAGES/KitPics/Adventurer 1.png"
import AssassinImg from "./IMAGES/KitPics/Assassin 1.png"
import BerserkerImg from "./IMAGES/KitPics/Berserker 1.png"
import BomberImg from "./IMAGES/KitPics/Bomber 2.png"
import BrawlerImg from "./IMAGES/KitPics/Brawler 2.png"
import ChemistImg from "./IMAGES/KitPics/Chemist 1.png"
import CookiemonsterImg from "./IMAGES/KitPics/Cookiemonster 1.png"
import CultivatorImg from "./IMAGES/KitPics/Cultivator 1.png"
import DruidImg from "./IMAGES/KitPics/Druid 1.png"
import EndermageImg from "./IMAGES/KitPics/Endermage 1.png"
import EngineerImg from "./IMAGES/KitPics/Engineer 1.png"
import ExplorerImg from "./IMAGES/KitPics/Explorer 1.png"
import FiremanImg from "./IMAGES/KitPics/Fireman 3.png"
import GamblerImg from "./IMAGES/KitPics/Gambler 1.png"
import GliderImg from "./IMAGES/KitPics/Glider 2.png"
import GrandpaImg from "./IMAGES/KitPics/Grandpa 1.png"
import GroundhogImg from "./IMAGES/KitPics/Groundhog 1.png"
import GuardianImg from "./IMAGES/KitPics/Guardian 1.png"
import KangarooImg from "./IMAGES/KitPics/Kangaroo 3.png"
import LumberjackImg from "./IMAGES/KitPics/Lumberjack 2.png"
import MilkmanImg from "./IMAGES/KitPics/Milkman 3.png"
import MongImg from "./IMAGES/KitPics/Monk 1.png"
import PhantomImg from "./IMAGES/KitPics/Phantom 2.png"
import PoseidonImg from "./IMAGES/KitPics/Poseidon 1.png"
import RecyclerImg from "./IMAGES/KitPics/Recycler 1.png"
import SnailImg from "./IMAGES/KitPics/Snail 1.png"
import SpiritImg from "./IMAGES/KitPics/Spirit 1.png"
import StomperImg from "./IMAGES/KitPics/Stomper 1.png"
import SunwalkerImg from "./IMAGES/KitPics/Sunwalker 2.png"
import SwitcherImg from "./IMAGES/KitPics/Switcher 1.png"
import ThorImg from "./IMAGES/KitPics/thor 1.png"
import VampireImg from "./IMAGES/KitPics/Vampire 1.png"
import ViperImg from "./IMAGES/KitPics/Viper 1.png"
import WerewolfImg from "./IMAGES/KitPics/Werewolf 2.png"

const KITDATA = 
[
    { name: "Adventurer", price: "free", description: "You can do a buncha quests to get rewards and stuff", image: AdventurerImg},
    { name: "Assassin", price: "free", description: "Charge up an attack while sneaking do deal massive damage", image: AssassinImg},
    { name: "Berserker", price: "free", description: "After getting a kill you become enraged and can deal lots of damage", image: BerserkerImg},
    { name: "Bomber", price: "free", description: "When killing anything, that thing explodes. You are immune to explosions.", image: BomberImg},
    { name: "Brawler", price: "free", description: "When using your fists, you deal increased damage", image: BrawlerImg},
    { name: "Chemist", price: "free", description: "You get 3 throwable potions with different effects, upon killing enemies your potions are refilled", image: ChemistImg},
    { name: "Cookie Monster", price: "free", description: "When eating cookies, you gain strength", image: CookiemonsterImg},
    { name: "Cultivator", price: "free", description: "When planting any seed or sapling, they are grown instantly", image: CultivatorImg},
    { name: "Druid", price: "free", description: "You get a special tree that heals all players around it. If you are a druid it gives you strength as well", image: DruidImg},
    { name: "Endermage", price: "free", description: "You start with a special portal that sends all players below the portal, to where the portal is placed", image: EndermageImg},
    { name: "Engineer", price: "free", description: "Start with a turret, upon placing this turret it shoots all nearby mobs, and players", image: EngineerImg},
    { name: "Explorer", price: "1,000", description: "why is this kit still in the game LMAO", image: ExplorerImg},
    { name: "Fireman", price: "free", description: "You are given immunity to fire, and a water bucket", image: FiremanImg},
    { name: "Gambler", price: "free", description: "You are able to press stone buttons for a random effect, the effects can be positive or negative", image: GamblerImg},
    { name: "Glider", price: "free", description: "You start with an elytra", image: GliderImg},
    { name: "Grandpa", price: "free", description: "You start with a stick that does a ton of knockback", image: GrandpaImg},
    { name: "Groundhog", price: "free", description: "Start with a shovel, upon activating it you can burrow deep underground", image: GroundhogImg},
    { name: "Guardian", price: "free", description: "You have the ability to attach to a player", image: GuardianImg},
    { name: "Kangaroo", price: "free", description: "You start with a kangaroo foot that allows you to jump really high up", image: KangarooImg},
    { name: "Lumberjack", price: "free", description: "you can do a buncha quests to get rewards and stuff", image: LumberjackImg},
    { name: "Milkman", price: "free", description: "Drink milk to get a few buffs", image: MilkmanImg},
    { name: "Monk", price: "free", description: "Recive a special monk staff, upon hitting player you put the weapon in there hand into there inventory", image: MongImg},
    { name: "Phantom", price: "free", description: "You recieve a feather that allows you to fly for a limited time when activating it", image: PhantomImg},
    { name: "Poseidon", price: "free", description: "You are stronger & faster in water", image: PoseidonImg},
    { name: "Recycler", price: "free", description: "i forgor", image: RecyclerImg},
    { name: "Snail", price: "free", description: "When hitting an enemy, you have a chance to give them slowness", image: SnailImg},
    { name: "Spirit", price: "free", description: "When activating your item, you go invisible for a limited time", image: SpiritImg},
    { name: "Stomper", price: "free", description: "You take reduced fall damage, when you fall and hit the ground players around you will take damage depending on how high you fell", image: StomperImg},
    { name: "Sunwalker", price: "free", description: "During the day you have increased speed", image: SunwalkerImg},
    { name: "Switcher", price: "free", description: "Recieve snowballs, when hitting a mob or player with these snowballs you swap positions with them", image: SwitcherImg},
    { name: "Thor", price: "free", description: "Receive an axe, when activating it you strike lighting down where you are aiming", image: ThorImg},
    { name: "Vampire", price: "free", description: "After killing a player, you heal. If you are max health, recieve an extra heart", image: VampireImg},
    { name: "Viper", price: "free", description: "When hitting an enemy, you have a chance to give them poison", image: ViperImg},
    { name: "Werewolf", price: "free", description: "When night time, you are faster and stronger", image: WerewolfImg}
]

export default KITDATA
