import { AiFillYoutube } from "react-icons/ai"
import { BsDiscord } from "react-icons/bs"

const Socials = () => 
{
    return(
    <div className="socials">
        <div className="backgroundimage-socials w-1/2 mx-auto p-2 border border-black mt-2 text-black sm:w-screen md:w-fit">
            <h1 className="font-title underline text-2xl">Socials</h1>
            <br/>
            <p className="text-xl font-basic flex">
                <SocialCard link="https://discord.gg/YbtRpDRC7Q" title="Discord" icon={<BsDiscord/>}/>
                <SocialCard link="https://discord.gg/YbtRpDRC7Q" title="Youtube"/>
            </p>
        </div>
    </div>
    )
}

const SocialCard = (props: any) => {
    return(
    <div className="SocialCard m-3 flex-auto">
        <a href={props.link} className={"hover:text-orange-500 rounded-md border border-black bg-gray-300 p-4"}>
            <span className="text-3xl m-2 p-2">{props.title}</span>
        </a>
    </div>
    )
}

export default Socials
